.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 567px;
    background: #192126;
    padding: 20px;
    margin-top: 50px;
}

.footerabout {
    display: flex;
    
    width: 514px;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    justify-content: center;
}

.footerabouttext {
    color: #FFF;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.followusetext {
    color: #FFF;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22.412px; /* 124.51% */
}

.socialIcons {
    display: flex;
    gap: 15px;
}

.socialIcon {
    width: 24px; /* Adjust as needed */
    height: 24px; /* Adjust as needed */
}

.footercompanydiv{
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
}

.companyhead{
    color: #A1A1AA;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 27.808px; /* 154.489% */
    letter-spacing: 3.792px;
}

.companydivitem{
    color: #FFF;
    font-family: Inter;
    font-size: 22.752px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
    line-height: 55.616px; /* 244.444% */
}

.whatsappshare{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
}

.whatsapptext{
    color: #A1A1AA;
    font-family: Inter;
    align-items: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 122.222% */
}

.newsletter{
    color: #A1A1AA;
    font-family: Inter;
    margin-top: 10px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 122.222% */
    letter-spacing: 3px;
}

.newsletterInputContainer {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    margin-top: 20px;
}

.newsletterInput {
    width: 300px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #A1A1AA;
    border-radius: 5px;
    font-size: 16px;
    color: #71717A;
    background-color: #FFF;
    display: flex;
    padding: 17px 16px 17px 20px;
    align-items: center;
    gap: 13px;
    align-self: stretch;
}

.subscribeButton {
    display: flex;
    width: 225px;
    padding: 20px 21px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: #256D9D;
    color: #FFF;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 100% */
    cursor: pointer;
}

.footershare{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    justify-content: center;
}

@media (max-width: 768px) {
    .footer {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        justify-content: center;
        align-items: center;
        height: fit-content;
        background: #192126;
        padding: 20px;
        margin-top: 50px;
        width: 90%;
    }

    .footerabout {
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        justify-content: center;
    }

    .footerabouttext {
        color: #FFF;
        font-family: Inter;
        font-size: 24px;
        text-align: center;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .footershare{
        display: flex;
        flex-direction: column;
        justify-content: center !important;
        align-items: center !important;
        align-items: flex-start;
        gap: 30px;
        justify-content: center;
    }

    .subscribeButton {
        display: flex;
        width: 225px;
        padding: 20px 21px;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        gap: 10px;
        border-radius: 12px;
        background: #256D9D;
        color: #FFF;
        text-align: center;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 100% */
        cursor: pointer;
    }
}

