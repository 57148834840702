.container{
  margin: 0 auto;
  /* max-width: 1536px; */
}

.divStyle {
  background-image: url("../../Images/image 21.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* width: 100%; */
  aspect-ratio: 2.5/1;
  display: flex;
  flex-direction: column; /* Arrange items vertically */
  align-items: left; /* Align items to the left */
  justify-content: center; /* Center items vertically */
}

.head1 {
  color: #fff;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.53);
  font-family: Inter;
  font-size: 120px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
  margin-left: 20px;
}
.head2 {
  color: #fff;
  background: rgba(255, 255, 255, 0.13); /* Semi-transparent background */
  backdrop-filter: blur(10px);
  text-align: justify !important;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  margin-left: 20px;
  max-width: 76%;
  padding:1vw;
}
.lineimg {
  display: flex;
  justify-content: center;
}
.line_img {
  height: 4%;
  width: 90%;
  align-self: stretch;
  margin-top: 2%;
}
/* src/pga.module.css */
.sec {
  height: 650px;
  justify-content: center;
  display: flex;
  /* background-color: red; */
}
.sec1 {
  display: flex;
  align-items: center;

  box-sizing: border-box;
  height: 600px;
  /* background-color: aqua; */
  width: 75%;
  box-shadow: 0px 23.782px 47.565px -5.946px rgba(40, 37, 35, 0.1),
    0px 2.973px 5.946px 0px rgba(40, 37, 35, 0.04);
  border-radius: 12px;
  background: var(--White-white-500, #fff);
  margin-top: 2%;
}

.person {
  flex: 1; /* Take up space proportionally */
  display: flex;
  justify-content: center;
  padding-right: 20px;
  height: 100%;
  /* background-color: blue; */
  width: 40%;
}

.person_img {
  width: 100%; /* Ensure the image scales responsively */
  height: 100%; /* Maintain aspect ratio */
}

.text {
  display: flex;
  flex-direction: column; /* Stack text content vertically */
  /* background-color: azure; */
  width: 60%;
  height: 100%;
  padding-top: 7%;
}

.textcontent {
  color: #53555a;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 25px;
  max-width: 100%;
  text-align: left;
}
.midtext {
  color: #575757;
  justify-content: center;

  text-align: center;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 114%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  width: 90%;
}
.hand {
  display: flex;
  margin-top: 2%;
  margin-bottom: 2%;
  justify-content: center;
}
.handshake {
  height: 5.5vw;
}

.memberimg {
  width: 100%;
  margin-bottom: 80px;
}

.devpattext{
  color: #3f4b52;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Inter;
  font-size: 2.8125vw;
  font-style: normal;
  font-weight: 600;
  line-height: 2vw;
  margin-top: 4vw;
}

.devpatdiv {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  width: 90%; /* Take 90% of the page width */
  margin: 2vw auto; /* Center the scroll view with top and bottom margins */
  justify-content: flex-start; /* Align items to the start */
}

.devpatitems {
  flex-shrink: 0; /* Prevent the items from shrinking */
  width: 45%; /* Each item will take 45% of the page width */
  background-color: #F2F9FD;
  padding: 1.5vw;
  border-radius: 1vw;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-right: 4%;
  margin-bottom: 2%; /* Add spacing between the items */
}

.shapporjiimage {
  display: flex;
  justify-content: flex-start;
  width: 5vw;
  height: 5vw;
}

.shapporjidivimage {
  display: flex;
  width: 100%;
  height: 15vw;
}

.shapporjidivimage1 {
  width: 100%;
}

.shapoorjitext {
  font-size: 1.5vw;
  font-weight: 400;
  padding: 1vw;
  text-align: justify;
}

.devpatdiv::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}

.navigationbuttons {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  margin-top: 1vw;
}

.navigationbuttons button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 2px;
  padding: 0.5vw 1vw;
  font-size: 18px;
  background-color: #fff; /* Button color */
  color: black; /* Text color */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition */
}

.navigationbuttons button:hover {
  background-color: #fff; /* Darker button color on hover */
}

.previmg{
  width: 16px;
  height: 16px;
  margin: 4px 8px;
}

.learnmore{
  margin: 0 auto;
  width: 90%;
  background-color: #256D9D;
  color: #fff;
  text-align: center;
  border-radius: 9px;
  padding: 8px;
  cursor: pointer;
}



/* Existing styles... */

@media (max-width: 768px) {
  .divStyle {
    aspect-ratio: 1/1;
    /* padding: 20px; */
    margin: 0 auto;
    margin-top: -40px;
    width: 100%;
    background-size: contain; /* Ensure the background image scales appropriately */
  }

  .searchbar {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
    padding: 10px 10px;
    margin-bottom: 20px;
    gap: 8px;
    width: 350px;
    height: 44px;
    border-radius: 26px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.searchIcon {
    width: 20px;
    height: 20px;
}

.searchInput {
    flex-grow: 1;
    border: none;
    outline: none;
    font-size: 16px;
}

.exploreButton {
    background-color: #C2E3DB;
    color: #256D9D;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    border: none;
    padding: 10px 10px;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px; /* Space between text and arrow */
}

.downArrowIcon {
    width: 12px;
    height: 12px;
}

  .head1 {
    font-size: 6vw; /* Increase text size on smaller screens */
    margin-left: 10px;
  }

  .head2 {
    font-size: 2.5vw; /* Adjust for readability on mobile */
    margin-left: 10px;
    max-width: 90%; /* Allow more space on mobile */
  }

  .line_img {
    width: 95%; /* Full width for mobile */
    height: 30%; /* Adjust height */
  }

  .sec {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto; /* Allow section height to adjust based on content */
    flex-direction: column; /* Stack the content vertically on smaller screens */
  }

  .sec1 {
    width: 95%; /* Use more of the screen width */
    flex-direction: column; /* Stack image and text vertically */
    height: auto; /* Adjust height to fit the content */
    padding: 10px; /* Add some padding */
    margin-top: 5%;
  }

  .person {
    width: 100%; /* Take up full width on mobile */
    padding-right: 0; /* Remove right padding */
    margin-bottom: 20px; /* Add spacing between image and text */
  }

  .person_img {
    width: 100%; /* Ensure the image fills the container */
    height: auto; /* Keep aspect ratio */
  }

  .text {
    width: 100%; /* Take full width */
    padding-top: 0; /* Remove unnecessary padding */
  }

  .textcontent {
    font-size: 14px; /* Adjust font size for readability */
    line-height: 20px;
  }

  .midtext {
    font-size: 16px; /* Reduce text size for mobile */
    padding-left: 10px;
    padding-right: 10px;
  }

  .handshake {
    height: 8vw; /* Adjust handshake image size for mobile */
  }

  .memberimg {
    margin-bottom: 40px; /* Reduce bottom margin on mobile */
  }

  .devpattext{
    color: #3f4b52;
    text-align: center;
    font-feature-settings: "liga" off, "clig" off;
    font-family: Inter;
    font-size: 8vw;
    font-style: normal;
    font-weight: 600;
    line-height: 2vw;
    margin-top: 15vw;
    margin-bottom: 10vw;
  }

  .devpatdiv{
    display: flex;
    
    flex-direction: column;
    justify-content: space-around;
    gap:10vw;
    width: 100%;
    margin-top: 2vw;
    margin-bottom: 2vw;
  }

  .devpatitems{
    width: 90%;
    background-color: #F2F9FD;
    margin: 0 auto;
    padding: 1.5vw;
    border-radius: 1vw;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .shapporjiimage{
    display: flex;
    justify-content: flex-start;
    width: 15vw;
    height: 15vw;
  }
  
  .shapporjidivimage{
    display: flex;
    width: 100%;
    height: 60vw;
  }
  
  .shapporjidivimage1{
    width: 100%;
  }
  
  .shapoorjitext{
    font-size: 3.5vw;
    font-weight: 400;
    padding: 1vw;
  }
}

/* Styles for smaller phones */
@media (max-width: 480px) {
  .divStyle {
    height: 250px;
  }

  .head1 {
    font-size: 8vw; /* Make the heading larger for small phones */
  }

  .head2 {
    font-size: 3vw; /* Adjust font size further */
  }

  .textcontent {
    font-size: 12px; /* Smaller text size */
  }

  .handshake {
    height: 10vw; /* Adjust further for small screens */
  }
}

