/* Base Styles */
.keyMembersContainer {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.h4 {
    font-size: 3vw;
    font-weight: 700;
}

.keyMembersDesc {
    margin-top: 1vw;
    color: #000;
    text-align: justify;
    font-family: Inter;
    font-size: 21px;
    font-weight: 500;
    line-height: 27px;
    padding: 40px;
}

.aboutContainer {
    width: 95%;
    margin-bottom: 2vw;
    height: fit-content;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0px 25px 50px -6px rgba(40, 37, 35, 0.1), 0px 3px 6px 0px rgba(0, 0, 0, 0.14);
}

.golferImage {
    width: 40%;
    border-radius: 12px;
    background-image: url('../../Images/golfer.png');
    background-position: center;
    background-size: cover;
}

.aboutList {
    width: 55%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.aboutText {
    color: #000;
    font-family: Inter;
    font-size: 21px;
    font-weight: 400;
    line-height: 27px;
    text-align: justify;
}

/* Media Queries for Mobile Responsiveness */

/* Tablet Screens (max-width: 1024px) */
@media (max-width: 1024px) {
    .aboutContainer {
        flex-direction: column;
        padding: 20px;
        width: 90%;
    }

    .golferImage {
        width: 90%;
        height: 300px;
        margin: 0 auto;
        margin-bottom: 20px;
    }

    .aboutList {
        width: 90%;
        margin: 0 auto;
    }

    .keyMembersDesc {
        width: 90%;
        font-size: 18px;
        line-height: 24px;
    }

    .h4 {
        font-size: 5vw;
    }
}

/* Mobile Screens (max-width: 768px) */
@media (max-width: 768px) {
    .aboutContainer {
        padding: 20px 10px;
    }

    .golferImage {
        height: 500px;
    }

    .keyMembersDesc {
        font-size: 16px;
    }

    .aboutText {
        font-size: 18px;
        line-height: 24px;
    }

    .h4 {
        font-size: 6vw;
    }
}
