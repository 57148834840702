.menu {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 250px; /* Increased width for a bigger menu */
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }
  
  .menuHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .menuHeader h2 {
    margin: 0;
    font-size: 18px;
  }
  
  .menuIcon {
    width: 60px; /* Adjust the size of the icon */
    height: auto;
    transform: scaleX(-1);
  }
  
  .menu ul {
    list-style: none;
    padding: 20px 0;
    margin: 0;
  }
  
  .menu li {
    margin: 10px 0;
    cursor: pointer;
    font-size: 16px;
    padding: 20px 0;
    color: #333;
    transition: color 0.3s ease;
  }
  
  .menu li:hover {
    color: #007BFF;
  }
  