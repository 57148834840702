.header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 113px;
    width: 100%;
}

.logoimg {
    width: 65px;
    height: 92px;
}

.leftheader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 80px;
}

.searchbar {
    display: flex;
    align-items: center;
    padding: 10px 14px;
    gap: 8px;
    /* width: 367px; */
    height: 44px;
    border-radius: 26px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.searchIcon {
    width: 20px;
    height: 20px;
}

.searchInput {
    flex-grow: 1;
    border: none;
    outline: none;
    font-size: 16px;
}

.exploreButton {
    background-color: #C2E3DB;
    color: #256D9D;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    border: none;
    padding: 10px 16px;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px; /* Space between text and arrow */
}

.downArrowIcon {
    width: 12px;
    height: 12px;
}

.navitems {
    display: flex;
    flex-direction: row;
    gap: 35.34px;
    color: #333;
    cursor: pointer;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.navtext{
    color:#333333;
}

.navtext1{
    color: #256D9D;
}

@media (max-width: 768px) {
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 10px;
        box-sizing: border-box;
        align-items: center;
        height: 113px;
        width: 100%;
    }

    .header {
        position: relative; /* Ensure positioning context for the overlay */
      }
      
      .blurOverlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
        backdrop-filter: blur(5px); /* Apply blur effect */
        z-index: 100; /* Make sure the overlay is above other content */
      }
      .menu {
        position: fixed;
        top: 0;
        right: 0;
        width: 250px; /* Adjust the width of the menu as needed */
        height: 100%;
        z-index: 101; /* Ensure the menu is above the overlay */
        /* Add additional styles for your menu here */
      }

      .burgerbutton{
        width: 60px;
      }
}



