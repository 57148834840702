/* Base Styles */
.container{
  /* max-width: 1536px; */
}


.mediaNewsSection {
  display: flex;
  box-sizing: border-box;
  padding: 226px 225px 227px 118px;
  background-repeat: no-repeat;
  background-size: cover;
}

.posterText {
  display: flex;
  flex-direction: column;
  height: 295px;
  width: 100%;
  justify-content: space-between;
}

.heading {
  height: 120px;
  width: 900px;
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.53);
  font-family: Inter;
  font-size: 130px;
  font-weight: 600;
  line-height: normal;
}

/* Services Section */
.servicesGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 50px;
  padding: 40px;
}

.serviceCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 486px;
}

.imageContainer {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  padding: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.serviceImage {
  width: 85px;
  height: 85px;
  padding: 1vw;
  border-radius: 50%;
}

.servicetitle {
  color: #000;
  font-family: Inter;
  font-size: 28px;
  font-weight: 600;
  line-height: 42px;
}

.servicedesc {
  color: #71717A;
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  text-align: justify;
}

.viewerdiv {
  display: flex;
  flex-direction: row;
  padding: 70px 110px;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  gap: 2vw;
  margin: 0 auto;
  margin-bottom: 20px;
  background: rgba(37, 109, 157, 0.05);
}

.bignumber {
  color: #17405B;
  text-align: center;
  font-family: Inter;
  font-size: 55px;
  font-weight: 700;
}

.bignumbertext {
  color: #71717A;
  text-align: center;
  font-family: Inter;
  font-size: 55px;
  font-weight: 500;
}

/* Media Queries for Mobile Responsiveness */

@media (max-width: 1024px) {
  .mediaNewsSection {
    padding: 150px 50px;
  }

  .searchbar {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
    padding: 10px 10px;
    margin-bottom: 20px;
    gap: 8px;
    width: 350px;
    height: 44px;
    border-radius: 26px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.searchIcon {
    width: 20px;
    height: 20px;
}

.searchInput {
    flex-grow: 1;
    border: none;
    outline: none;
    font-size: 16px;
}

.exploreButton {
    background-color: #C2E3DB;
    color: #256D9D;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    border: none;
    padding: 10px 10px;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px; /* Space between text and arrow */
}

.downArrowIcon {
    width: 12px;
    height: 12px;
}

  .heading {
    font-size: 80px;
    width: auto;
  }

  .servicesGrid {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    padding: 20px;
  }

  .viewerdiv {
    flex-direction: column;
    padding: 50px 20px;
    width: 90%;
    gap:60px;
  }

  .bignumber,
  .bignumbertext {
    font-size: 40px;
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .mediaNewsSection {
    padding: 100px 20px;
  }

  .heading {
    font-size: 60px;
  }

  .servicesGrid {
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 20px;
  }

  .serviceCard {
    height: auto;
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  }

  .viewerdiv {
    padding: 40px 10px;
    margin: 0 auto;
  }

  .bignumber,
  .bignumbertext {
    font-size: 35px;
  }

  .servicetitle {
    color: #000;
    font-family: Inter;
    font-size: 18px;
    text-align: left;
    font-weight: 600;
    line-height: 42px;
  }

  .servicedesc {
    color: #71717A;
    font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
  }

  .imageContainer {
    width: 62px;
    height: 62px;
    border-radius: 50%;
    padding: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .serviceImage {
    width: 65px;
    height: 65px;
    padding: 1vw;
    border-radius: 50%;
  }
}
