.page-2-content-container {
  display: flex;
  flex-direction: column;
  /* max-width: 1536px; */
}
.page2-ourStory {
  width: 100%;
  /* height: 50rem; */
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 6vw;
}
.page2-ourStory-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50vw;
  height: 25rem;
}

.page2-ourStory-right {
  position: relative;
  width: 50vw;
  height: 25rem;
  box-sizing: border-box;
  padding: 1vw;
}
.page2-ourStory-left-h1 {
  color: #1f1f1f;
  font-family: Inter;
  font-size: 3.4375rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.4375rem;
  text-transform: capitalize;
}
.page2-ourStory-left-p {
  color: #000;
  font-family: Inter;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.5rem;
  text-transform: capitalize;
  text-align: justify;
  margin-right: 20px;
}
.page2-ourStory-right-Img1 {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: auto;
  border-radius: 0.75rem;
}
.page2-ourStory-right-Img2 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  height: auto;
  border-radius: 0.75rem;
}
.page2-ourStory-right-ImgBackground {
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 90%;
  width: 80%;
  border-radius: 0.75rem 0.75rem 0rem 0.75rem;
  background: #37709b;
}

.page2-mission-vision {
  display: flex;
  box-sizing: border-box;
  padding: 10.25rem 4rem;
  justify-content: space-between;
  align-items: center;
  gap: 14rem;
  background-image: url("../../Images/missionVision.png");
  background-repeat: no-repeat;
  flex-direction: row;
}

.page2-mission-vision-center-left {
  display: flex;
  flex-direction: column;
  /* width: 40.875rem; */
  box-sizing: border-box;
  padding: 2rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.55194rem;
  background: #fff;
  box-shadow: 0px 0px 1.766px 0px rgba(24, 20, 31, 0.06),
    0px 14.129px 31.791px 0px rgba(24, 20, 31, 0.15);
}
.page2-mission-vision-center-right {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  /* width: 40.875rem; */
  padding: 2rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.55194rem;
  background: #fff;
  box-shadow: 0px 0px 1.766px 0px rgba(24, 20, 31, 0.06),
    0px 14.129px 31.791px 0px rgba(24, 20, 31, 0.15);
}
.page2-mission-vision-center-h1 {
  color: #333;
  text-align: center;
  font-family: Inter;
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.40644rem;
  letter-spacing: 0.01081rem;
  margin-bottom: 3rem;
}
.page2-mission-vision-center-p {
  color: #000;
  text-align: justify;
  font-family: Inter;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3.5rem;
}
.page2-Management {
  display: flex;
  width: 100%;
  margin-top: 3rem;
  /* height: 129.125rem; */
  flex-direction: column;
  align-items: center;

  gap: 5.25rem;
  flex-shrink: 0;
}
.page2-Management-Founder {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}
.page2-Management-Founder-h1 {
  display: flex;
  width: 33.875rem;
  height: 3.25rem;
  flex-direction: column;
  justify-content: center;
  color: #3f4b52;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Inter;
  font-size: 2.8125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.25rem;
}
.page2-Management-Founder-p {
  width: 65%;
  color: #333;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem;
}
.page2-Management-Founder-card {
  display: flex;
  width: 25.4375rem;

  flex-direction: column;
  align-items: center;
  gap: 1.1875rem;
  border-radius: 0.75rem;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(37, 109, 157, 0.46),
    0px 4px 4px 0px rgba(83, 85, 90, 0.59);
}
.page2-Management-Founder-card-upperSection {
  display: flex;
  width: 75%;
  padding: 0rem 1.6965rem 0rem 4.5625rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.73763rem;
  border-radius: 0.73763rem 0.73763rem 0rem 0rem;
  background: #256d9d;
}
.page2-Management-Founder-card-upperSection-alok {
  background-image: url("../../Images/Alok.png");
  width: 19.9375rem;
  height: 18.125rem;
}
.page2-Management-Founder-card-lowerSection {
  display: flex;
  width: 25.4375rem;
  padding-bottom: 1.23856rem;
  flex-direction: column;
  align-items: center;
  gap: 1.1875rem;
  border-radius: 0.75rem;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(37, 109, 157, 0.46),
    0px 4px 4px 0px rgba(83, 85, 90, 0.59);
}
.page2-Management-Founder-card-lowerSection-h1 {
  display: flex;
  height: 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  color: #000;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.125rem;
}
.page2-Management-Founder-card-lowerSection-p {
  height: 4.86819rem;
  align-self: stretch;
  color: #1e1e1e;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.625rem;
}
.page2-Management-Founder-card-lowerSection-socials {
  display: flex;
  width: 20.21031rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.page2-Founder-linkedin {
  background-image: url("../../Images/LinkedIn.png");
  width: 1.91775rem;
  height: 1.91775rem;
}
.page2-Founder-twitter {
  background-image: url("../../Images/twitter.png");
  width: 1.91775rem;
  height: 1.91775rem;
}
.page2-Leadership {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page2-Leadership-heading {
  color: #3f4b52;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Inter;
  font-size: 2.8125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
}
.page2-Leadership-leaders {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 3rem;
  gap: 3rem;
}
.page2-Leadership-leaders-blum {
  background-image: url("../../Images/blum.png");
  width: 23rem;
  height: 30rem;
  border-radius: 0.75rem;
  border: 2px solid #000;
  position: relative;
}
.page2-Leadership-leaders-bajaj {
  background-image: url("../../Images/bajaj.png");
  width: 23rem;
  height: 30rem;
  border-radius: 0.75rem;
  border: 2px solid #000;
  position: relative;
}
.page2-Leadership-leaders-blum-heading {
  position: absolute;
  bottom: 12%;
  left: 30%;
  transform: translateX(-50%);
  font-size: 1.5rem;
  text-align: center;
  width: 100%;
  padding: 0 1rem;

  color: #fff;

  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.page2-Leadership-leaders-blum-position {
  position: absolute;
  bottom: 7%;
  left: 40%;
  transform: translateX(-50%);
  font-size: 1.5rem;
  text-align: center;
  width: 100%;
  padding: 0 1rem;

  color: #fff;

  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.page2-Leadership-leaders-bajaj-heading {
  position: absolute;
  bottom: 12%;
  left: 30%;
  transform: translateX(-50%);
  font-size: 1.5rem;
  text-align: center;
  width: 100%;
  padding: 0 1rem;

  color: #333;

  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.page2-Leadership-leaders-bajaj-position {
  position: absolute;
  bottom: 7%;
  left: 46%;
  transform: translateX(-50%);
  font-size: 1.5rem;
  text-align: center;
  width: 100%;
  padding: 0 1rem;

  color: #333;

  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.page2-partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 2rem;
}
.page2-partners-heading {
  color: #3f4b52;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Inter;
  font-size: 2.8125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
}
.Page2-partners-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding: 3rem; */
  gap: 7.9rem;
}
.Page2-partners-container-TIM-LOBB {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  background-color: rgba(113, 113, 122, 0.04);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition */
}
.Page2-partners-TIM-LOBB-heading {
  color: #000;
  font-family: Inter;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.035rem;
  box-sizing: border-box;
  padding: 2rem;
}
.Page2-partners-TIM-LOBB-info {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 3rem;
}
.Page2-partners-TIM-LOBB-info-left {
  width: 16.40106rem;
  height: 16.0625rem;
}
.Page2-partners-TIM-LOBB-info-right {
  width: 16.40106rem;
  height: 18.0625rem;
  text-align: justify;
}
.Page2-partners-TIM-LOBB-learn-more {
  color: white;
  width: 40rem;
  border-radius: 0.5125rem;
  background: #256d9d;
  padding: 0.85413rem 0.89681rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Page2-partners-container-colin {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Page2-partners-colin-heading {
  color: #000;
  font-family: Inter;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.035rem;
  box-sizing: border-box;
  padding: 2rem;
}
.Page2-partners-colin-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
}
.Page2-partners-colin-info-left {
  width: 14.40106rem;
  height: 16.0625rem;
}
.Page2-partners-colin-info-right {
  width: 16.40106rem;
  height: 18.0625rem;
  text-align: justify;
}
.Page2-partners-colin-learn-more {
  color: white;
  width: 40rem;
  border-radius: 0.5125rem;
  background: #256d9d;
  padding: 0.85413rem 0.89681rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page2-getintouch {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 7rem;
  border-radius: 12px;
  background: var(--White-white-500, #fff);
  padding: 4rem;
  box-sizing: border-box;
  box-shadow: 0px 23.782px 47.565px -5.946px rgba(40, 37, 35, 0.12),
    0px 2.973px 5.946px 0px rgba(40, 37, 35, 0.14);
  margin-bottom: 5rem;
}
.page2-getintouch-left {
  display: flex;
  width: 50.5625rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}
.page2-getintouch-heading {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Inter;
  font-size: 3.4375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;
  letter-spacing: -0.01956rem;
  margin-bottom: 1.5rem;
}
.page2-getintouch-p {
  color: #575757;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.25rem;
  letter-spacing: 0.0625rem;
}
.text-underline {
  text-decoration: underline;
  cursor: pointer;
}
.page2-getintouch-right {
  display: flex;
  flex-direction: column;
  width: 25rem;
  gap: 1rem;
}
.page2-getintouch-heading-right {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Inter;
  font-size: 2.4375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;
  letter-spacing: -0.01956rem;
}
.page2-getintouch-p-right {
  color: var(--Color-Grey-60, #767676);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75963rem;
  letter-spacing: -0.01956rem;
  text-align: justify;
}
.page2-getintouch-form-label {
  color: #767676;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.08288rem;
  letter-spacing: -0.01469rem;
}
.page2-getintouch-form-container {
  position: relative; /* Added this line */
  display: flex; /* Ensure input and icon are aligned */
  width: 23.75rem; /* Match with input width */
}

.page2-getintouch-form-input {
  width: 100%; /* Allow input to take full width of the container */
  height: 2.24856rem;
  border-radius: 0.40606rem;
  padding: 0.5vw;
  border: 2.166px solid #d2d2d2;
  font-size: 18px;
  padding-right: 30px; /* Add padding to the right to avoid text overlap with icon */
}

.page2-getintouch-form-icon {
  position: absolute;
  right: 10px; /* Adjusted position */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Center vertically */
  width: 20px;
  height: 20px;
  pointer-events: none;
}

.page2-getintouch-form-input-message {
  display: flex;
  width: 23rem;
  height: 9.24856rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.40606rem;
  font-size: 18px;
  padding: 0.5vw;
  border: 2.166px solid #d2d2d2;
}
.page2-getintouch-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 1.25rem 1.3125rem;
  border-radius: 0.75rem;
  background: #256d9d;
}

/* General mobile responsiveness */
@media (max-width: 768px) {
    .page2-ourStory {
        flex-direction: column;
        padding: 4vw;
        margin: 0 auto;
        width: 90%;
    }

    .searchbar {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      align-items: center;
      padding: 10px 10px;
      margin-bottom: 20px;
      gap: 8px;
      width: 350px;
      height: 44px;
      border-radius: 26px;
      border: 1px solid #D0D5DD;
      background: #FFF;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
    
    .searchIcon {
        width: 20px;
        height: 20px;
    }
    
    .searchInput {
        flex-grow: 1;
        border: none;
        outline: none;
        font-size: 16px;
    }
    
    .exploreButton {
        background-color: #C2E3DB;
        color: #256D9D;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        border: none;
        padding: 10px 10px;
        border-radius: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px; /* Space between text and arrow */
    }
    
    .downArrowIcon {
        width: 12px;
        height: 12px;
    }

    .page2-ourStory-right{
        position: relative;
        width: 50vw; 
        height: 25rem; 
        box-sizing: border-box;
        padding: 1vw;
        margin-top: 300px;
    }

    .page2-ourStory-left, .page2-ourStory-right {
        width: 100%;
    }

    .page2-ourStory-left-h1 {
        font-size: 2.5rem;
        line-height: 2rem;
        margin: 0 auto;
        margin-top: 300px;
    }

    .page2-ourStory-left-p {
        text-align: justify;
        
        font-size: 1rem;
        line-height: 3rem;
    }

    .page2-mission-vision {
        flex-direction: column;
        margin: 0 auto;
        margin-top: 10px;
        padding: 1rem 2rem;
        gap: 2rem;
    }

    .page2-mission-vision-center-left, .page2-mission-vision-center-right {
        width: 100% !important;
        height: 5% !important;
        padding: 1rem;
    }

    .page2-mission-vision-center-p {
      color: #000;
      text-align: justify;
      font-family: Inter;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
    }

    .page2-ourStory-right-Img1 {
        position: absolute;
        top: 0;
        right: 0;
        width: 80%; 
        height: auto; 
        border-radius: 0.75rem;
    }
    .page2-ourStory-right-Img2{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 80%; 
        height: auto; 
        border-radius: 0.75rem;
    }

    .page2-Management {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      width: 60%;
        gap: 2rem;
    }

    .page2-Management-Founder-h1 {
      display: flex;
      width: 25.875rem;
      height: 3.25rem;
      flex-direction: column;
      justify-content: center;
      color: #3f4b52;
      text-align: center;
      font-feature-settings: "liga" off, "clig" off;
      font-family: Inter;
      font-size: 2rem;
      font-style: normal;
      font-weight: 600;
      line-height: 2.25rem;
    }

    .page2-Management-Founder-p {
        font-size: 1.2rem;
        width: 80%;
    }

    .page2-getintouch-p {
      color: #575757;
      font-feature-settings: "liga" off, "clig" off;
      font-family: Inter;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 600;
      line-height: 2.25rem;
      letter-spacing: 0.0625rem;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .page2-Leadership-leaders {
        flex-direction: column;
        gap: 2rem;
        padding: 2rem 1rem;
    }

    .page2-Leadership-heading {
      color: #3f4b52;
      text-align: center;
      font-feature-settings: "liga" off, "clig" off;
      font-family: Inter;
      font-size: 2rem;
      font-style: normal;
      font-weight: 600;
      line-height: 2rem;
    }

    .page2-partners {
        padding: 0rem;
    }

    .Page2-partners-container {
        flex-direction: column;
        gap: 1rem;
    }

    .page2-getintouch {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 2rem;
          border-radius: 12px;
          background: var(--White-white-500, #fff);
          padding: 0rem;
          box-sizing: border-box;
          box-shadow: 0px 23.782px 47.565px -5.946px rgba(40, 37, 35, 0.12),
            0px 2.973px 5.946px 0px rgba(40, 37, 35, 0.14);
          margin-bottom: 5rem;

    }

    .page2-getintouch-left {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        text-align: center;
        width: 90%;
    }

    .page2-getintouch-right {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        text-align: center;
        width: 90%;
        /* padding: 0.5rem; */
    }

    .page2-mission-vision {
        display: flex;
        box-sizing: border-box;
        padding: 2rem 2rem;
    
        justify-content: space-between;
        align-items: center;
        gap:6rem;
        background-image: url('../../Images/missionVision.png'); 
        background-size: cover;
        background-repeat: no-repeat;
        flex-direction: column;
    }

    .Page2-partners-TIM-LOBB-learn-more{
        color: white;
        width: 90%;
        border-radius: 0.5125rem;
        background: #256D9D;
        padding: 0.85413rem 0.89681rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0px;
    }

    .Page2-partners-colin-learn-more{
        color: white;
        width: 20rem;
        border-radius: 0.5125rem;
        background: #256D9D;
        padding: 0.85413rem 0.89681rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 120px;
    }

    .page2-partners{
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
    }

    .page2-getintouch-heading-right {
      color: #000;
      font-feature-settings: "liga" off, "clig" off;
      font-family: Inter;
      font-size: 1.7375rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.75rem;
      letter-spacing: -0.01956rem;
    }

    .page2-getintouch-form-input {
      display: flex;
      width: 20.75rem;
      height: 2.24856rem;
      justify-content: center;
      align-items: center;
      border-radius: 0.40606rem;
      border: 2.166px solid #d2d2d2;
    }

    .page2-getintouch-form-input-message {
      display: flex;
      width: 20.75rem;
      height: 5.24856rem;
      justify-content: center;
      align-items: center;
      border-radius: 0.40606rem;
      border: 2.166px solid #d2d2d2;
    }

    .page2-partners-heading {
      color: #3f4b52;
      text-align: center;
      font-feature-settings: "liga" off, "clig" off;
      font-family: Inter;
      font-size: 2rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1rem;
    }

    .Page2-partners-TIM-LOBB-info-left {
      width: 45%;
      height: 12.0625rem;
    }

    .Page2-partners-container-TIM-LOBB {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80%;
      margin: 0 auto;
      padding: 10px;
      margin-top: 20px;
      box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.3);
    }

    .Page2-partners-container-colin {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80%;
      margin: 0 auto;
    }

    .Page2-partners-TIM-LOBB-info-right{
      text-align: center;
        width: 45%;
        height: 16rem;
        text-align: justify;
        font-size: 12px;
    }

    .Page2-partners-colin-info-left {
      width: 11.40106rem;
      height: 16.0625rem;
    }
    .Page2-partners-colin-info-right {
      width: 9rem;
      height: 25rem;
    }

    .page2-getintouch-heading {
      color: #000;
      font-feature-settings: "liga" off, "clig" off;
      font-family: Inter;
      font-size: 2.4375rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.75rem;
      letter-spacing: -0.01956rem;
      margin-bottom: 1.5rem;
      margin-top: 10vw;
    }

    .Page2-partners-TIM-LOBB-heading {
      color: #000;
      font-family: Inter;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.035rem;
      box-sizing: border-box;
      padding: 2rem;
    }

    .Page2-partners-colin-heading {
      color: #000;
      font-family: Inter;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.035rem;
      box-sizing: border-box;
      padding: 2rem;
    }

    .page2-getintouch-form-container {
      margin: 0 auto;
      position: relative; /* Added this line */
      display: flex; /* Ensure input and icon are aligned */
      width: 100%;
      padding: 0.5vw; /* Match with input width */
    }

    .page2-getintouch-form-icon {
      position: absolute;
      right: 20px; /* Adjusted position */
      top: 50%; /* Center vertically */
      transform: translateY(-50%); /* Center vertically */
      width: 20px;
      height: 20px;
      pointer-events: none;
    }
    .page2-getintouch-form-input-message {
      display: flex;
      width: 100%;
      height: 9.24856rem;
      justify-content: center;
      align-items: center;
      border-radius: 0.40606rem;
      font-size: 18px;
      padding: 0.5vw;
      border: 2.166px solid #d2d2d2;
      margin: 0 auto;
    }

    .page2-getintouch-submit {
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      padding: 1.25rem 1.3125rem;
      border-radius: 0.75rem;
      background: #256d9d;
      margin-bottom: 20px;
      width: 90%;
    }

    .page2-getintouch-form-label {
      color: #767676;
      font-family: Inter;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.08288rem;
      letter-spacing: -0.01469rem;
      text-align: start;
      width: 100%;
      margin-bottom: -10px;
      margin-left: 10px;
      font-weight:700;
    }
}