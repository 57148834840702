.container {
  display: flex;
  flex-direction: column;
  /* max-width: 1536px; */
}
.mediaNewsSection {
  display: flex;
  box-sizing: border-box;
  padding: 226px 225px 227px 118px;
  background-repeat: no-repeat;
  background-size: cover;
}
.posterText {
  display: flex;
  flex-direction: column;
  height: 295px;
  width: 100%;
  justify-content: space-between;
}
.heading {
  height: 156px;
  align-self: stretch;
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.53);
  font-family: Inter;
  font-size: 130px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.posterDesc {
  display: flex;
  align-self: stretch;
  background: rgba(255, 255, 255, 0.13); /* Semi-transparent background */
  backdrop-filter: blur(10px);
  color: #fff;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 10px;
  text-align: justify !important;
}
.recentContainer {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 3rem 4rem;
  padding: 28px 39px;
  gap: 50px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid rgba(161, 161, 170, 0.07);
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.recentNews {
  display: flex;
  flex-direction: column;
  gap: 29px;
}
.individualLine {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 36.647px;
  text-align: justify;
}
.individualLine3 {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.523%;
  text-align: justify;
}
.individualUrl {
  color: #256d9d;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 114.523%; /* 36.647px */
  text-decoration-line: underline;
}
.trainingCont {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin: 5rem 4rem;
}
.trainDevCont {
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 116.667% */
}
.trainHeading {
  display: flex;
  height: 47.065px;
  flex-direction: column;
  justify-content: center;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 45px;
  font-style: normal;
  font-weight: 500;
}
.trainPara1 {
  color: #333;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: justify;
}
.trainPara2 {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 116.667% */
  align-self: stretch;
  text-align: justify;
}
.litext{
  text-align: justify;
}
.subHeadTrain {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 116.667% */
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 0 10px;
  }

  .searchbar {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
    padding: 10px 10px;
    margin-bottom: 20px;
    gap: 8px;
    width: 350px;
    height: 44px;
    border-radius: 26px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.searchIcon {
    width: 20px;
    height: 20px;
}

.searchInput {
    flex-grow: 1;
    border: none;
    outline: none;
    font-size: 16px;
}

.exploreButton {
    background-color: #C2E3DB;
    color: #256D9D;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    border: none;
    padding: 10px 10px;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px; /* Space between text and arrow */
}

.downArrowIcon {
    width: 12px;
    height: 12px;
}
  .mediaNewsSection {
    padding: 100px 20px;
    background-position: center;
  }
  .posterText {
    height: auto;
  }
  .heading {
    font-size: 48px;
    line-height: 1.2;
    text-align: center;
  }
  .posterDesc {
    font-size: 18px;
    text-align: center;
  }
  .recentContainer {
    margin: 2rem 1rem;
    padding: 20px;
  }
  .individualLine, .individualLine3 {
    font-size: 18px;
  }
  .individualUrl {
    font-size: 16px;
    word-break: break-all;
  }
  .trainingCont {
    margin: 2rem 1rem;
    gap: 20px;
  }
  .trainDevCont {
    font-size: 16px;
  }
  .trainHeading {
    font-size: 28px;
  }
  .trainPara1, .trainPara2 {
    font-size: 16px;
  }
}
