.maindiv{
  /* max-width: 1536px; */
}

.backimg {
  position: absolute;
  width: 100%;
  height: 855px;
  z-index: -100;
}

.logoonback {
  display: flex;
  margin: 0 auto;
  width: 226px;
  height: 332px;
  margin-top: 54px;
}

.socialIcons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.icon {
  width: 75px;
  height: 75px;
  margin: 0 10px;
  cursor: pointer;
}

.text1 {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text2 {
  display: flex;
  height: 91px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  align-self: stretch;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 62.96px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.text3 {
  display: flex;
  width: 1240px;
  height: 131px;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.textContainer {
  display: flex;
  justify-content: center;
  margin-top: 80px;
}

.text {
  color: #000;
  font-family: Inter;
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: 114%; /* 51.3px */
}

/* New CSS for the alternating image and text layout */
.features {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 50px;
}

/* Each feature item */
.featureItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.featureItem1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.golfcoursediv {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 44px;
}

.golfcoursetext {
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: 111%; /* 49.95px */
}

.insidegolfcoursediv {
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 90%;
  height: 976px;
  padding: 42px;
  gap: 36px;
  border-radius: 12px;
  background: var(--White-white-500, #fff);
  box-shadow: 0px 23.782px 47.565px -5.946px rgba(40, 37, 35, 0.1),
    0px 2.973px 5.946px 0px rgba(0, 0, 0, 0.14);
}

.golfcourseimg {
  width: 621px;
  height: 892px;
  border-radius: 12px 0px 0px 12px;
  background: lightgray -34.84px 0px / 111.221% 100% no-repeat;
}

.golfcoursetextdiv {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 717px;
}

.minitexts {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 130% */
  text-align: justify;
}

.miniheads {
  color: #1c1d1f;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 108.333% */
}

/* Left-aligned image */
/* .leftImage .featureImage {
    order: 1;
} */

/* Right-aligned image */
/* .rightImage .featureImage {
    order: 1;
} */

/* Styling for the feature images */
.featureImage {
  width: 339px;
  height: 525px;
  object-fit: cover;
}

.featureImage1 {
  width: 600px;
  height: 400px;
  object-fit: cover;
}

.headfeatureitem {
  color: #333;
  font-family: Inter;
  font-size: 34.578px;
  font-style: normal;
  font-weight: 700;
  line-height: 114%; /* 39.419px */
  margin-left: 20px;
  margin-right: 20px;
}

.featureitemtext {
  display: flex;
  flex-direction: column;
  text-align: left;
}

/* Styling for the feature text */
.featureText {
  font-family: Inter;
  font-size: 24px;
  font-weight: 500;
  color: #000;
  flex: 1;
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
  color: #333;
  font-family: Inter;
  font-size: 26.377px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 113.734% */
  text-align: justify;
}

.tournadiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-left: 40px;
  margin-right: 40px;
  width: 90%;
}

.tournatext {
  display: flex;
  height: 47px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  color: #292929;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Inter;
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tounaminitext {
  display: flex;
  height: 112px;
  transform: rotate(0.192deg);
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  color: #000;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px; /* 141.667% */
  text-align: justify;
}

.xscrolldiv {
  display: flex;
  flex-direction: row;
  width: 90vw;
  overflow-x: scroll;
  gap: 20px;
  padding: 20px;
  scroll-behavior: smooth;
  margin: 0 auto;
}

.card {
  width: 600px;
  height: 300px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-shrink: 0;
}

.card img {
  width: 100%;
  height: 60%;
  object-fit: cover;
}

.card .heading {
  height: 20%;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

.card .description {
  height: 20%;
  padding: 10px;
  font-size: 16px;
  text-align: justify;
  color: #666;
}

/* Hide scrollbar */
.xscrolldiv::-webkit-scrollbar {
  display: none;
}

.xscrolldiv {
  -ms-overflow-style: none;
  scrollbar-width: none;
  cursor: grab;
}

  .dotContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 50px;
  }
  
  .dot {
    width: 12px;
    height: 12px;
    background-color: lightgray;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .activeDot {
    background-color: blue;
  }

  .navigationbuttons {
    display: flex;
    justify-content: space-between;
    width: 90vw;
    margin: 0 auto;
    margin-top: 1vw;
    margin-left: 5vw;
  }
  
  .navigationbuttons button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 0.5vw 1vw;
    font-size: 18px;
    background-color: #fff; /* Button color */
    color: black; /* Text color */
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
    transition: box-shadow 0.3s ease, transform 0.3s ease; /* Smooth transition */
  }
  
  .navigationbuttons button:hover {
    background-color: #fff; /* Darker button color on hover */
  }

  .previmg{
    width: 16px;
    height: 16px;
    margin: 4px 8px;
  }

  @media (max-width: 768px) {
    .maindiv {
        padding: 0;
        text-align: center;
    }

    .searchbar {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        align-items: center;
        padding: 10px 10px;
        margin-bottom: 20px;
        gap: 8px;
        width: 350px;
        height: 44px;
        border-radius: 26px;
        border: 1px solid #D0D5DD;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
    
    .searchIcon {
        width: 20px;
        height: 20px;
    }
    
    .searchInput {
        flex-grow: 1;
        border: none;
        outline: none;
        font-size: 16px;
    }
    
    .exploreButton {
        background-color: #C2E3DB;
        color: #256D9D;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        border: none;
        padding: 10px 10px;
        border-radius: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px; /* Space between text and arrow */
    }
    
    .downArrowIcon {
        width: 12px;
        height: 12px;
    }

    .backimg {
        position: absolute;
        height: 400px;
        left:0;
    }

    .logoonback {
        display: flex;
        width: 10%;
        height: 10%;
        margin: 0 auto;
        margin-top: 40px;
        /* display: none; */
    }

    .socialIcons {
        margin: 20px 0;
    }

    .icon {
        width: 75px;
        height: 75px;
    }

    .text1 {
        font-size: 25px;
        margin-bottom: 10px;
    }

    .text2 {
        height: 40px;
        font-size: 32px;
        margin-bottom: 20px;
    }

    .text3 {
        color: white;
        height: 60px;
        font-size: 12px;
        width: 100%;
    }

    .text {
        font-size: 45px;
    }

    .features {
        flex-direction: column;
    }

    .featureItem{
        flex-direction: column;
    }

    .featureItem1 {
        flex-direction: column-reverse; /* Default to row layout for larger screens */
    }

    .featureImage, .featureImage1 {
        width: 80%;
        height: auto;
        object-fit: fill;
        margin-top: 10px;
        margin-bottom: 20px; /* Adjusted width for larger screens */
    }

    .headfeatureitem {
        display: flex;
        flex-direction: column;
        font-size: 34.578px;
    }

    .featureText {
        font-size: 26.377px;
    }

    .golfcoursetext {
        font-size: 30px;
    }

    .insidegolfcoursediv {
        height: fit-content !important;
        flex-direction: row;
    }

    .golfcourseimg {
      width: 100%;
      height: 500px;
        padding: 0;
    }

    .golfcoursetextdiv{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;

    }

    .minitexts {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 20px;
    }

    .miniheads {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 24px;
    }

    .tournatext {
      margin: 40px auto;
      font-size: 25px;
    }

    .tounaminitext {
        margin-top: 80px;
        margin-bottom: 40px;
        font-size: 20px;
        margin-bottom: 100px;
    }

    .xscrolldiv {
        gap: 20px;
    }

    .card {
        max-width: 100%;
    }

    .card .heading {
        font-size: 20px;
    }

    .card .description {
        font-size: 10px;
    }

    .dot {
        width: 12px;
        height: 12px;
    }

    .insidegolfcoursediv{
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 70%;
        height: 976px;
        /* padding: 42px; */
        gap: 36px;
        border-radius: 12px;
        background: var(--White-white-500, #FFF);
        box-shadow: 0px 23.782px 47.565px -5.946px rgba(40, 37, 35, 0.10), 0px 2.973px 5.946px 0px rgba(0, 0, 0, 0.14);
    }

    .tournadiv{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        width: 80%;
    }

    .xscrolldiv {
        display: flex;
        flex-direction: column;
        width: 100%;
        /* overflow-x: scroll; */
        gap: 20px;
        padding: 20px;
        scroll-behavior: smooth;
      }
      
      .card {
        width: 90%;
        height: 300px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        overflow: hidden;
        flex-shrink: 0;
      }
      
      .card img {
        width: 100%;
        height: 60%;
        object-fit: cover;
      }
      
      .card .heading {
        height: 20%;
        background-color: #f4f4f4;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 600;
        color: #333;
      }
      
      .card .description {
        height: 20%;
        padding: 10px;
        font-size: 14px;
        text-align: center;
        color: #666;
      }
      
      /* Hide scrollbar */
      .xscrolldiv::-webkit-scrollbar {
        display: none;
      }
      
      .xscrolldiv {
        -ms-overflow-style: none; 
        scrollbar-width: none;
      }
}

